import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { useFinchConnect } from 'react-finch-connect';

import { Box, Button, Heading, Flex, Text, Icon } from '@chakra-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import landing_page_logo from './landing_page_logo.png';
import landing_page_logo_sandbox from './landing_page_logo_sandbox.png';

import './App.css';

const clientId = process.env.REACT_APP_FINCH_CLIENT_ID;
const sandboxClientId = process.env.REACT_APP_SANDBOX_FINCH_CLIENT_ID;

const employeeHeaders = [
  {
    name: 'Company Name',
    key: 'company.name',
  },
  {
    name: 'Title',
    key: 'title',
  },
  {
    name: 'Employment Type',
    key: 'type',
  },
];

const directoryHeaders = [
  {
    name: 'First Name',
    key: 'first_name',
  },
  {
    name: 'Last Name',
    key: 'last_name',
  },
  {
    name: 'Title',
    key: 'title',
  },
  {
    name: 'Department',
    key: 'department.name',
  },
];

const companyHeaders = [
  {
    name: 'Name',
    key: 'legal_name',
  },
  {
    name: 'EIN',
    key: 'ein',
  },
  {
    name: 'Contact Email',
    key: 'primary_email',
  },
];

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/sandbox">
          <Sandbox />
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </Router>
  );
};

function Sandbox() {
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [directory, setDirectory] = useState(null);
  const [connected, setConnected] = useState(false);

  const onSuccess = async ({ code }) => {
    setConnected(true);
    const res = await axios.post(`https://erc-today-api.tryfinch.com/callback`, {
      code,
      state: 'dev',
    });
    if (res.status === 200) {
      const { data } = res;
      if (Array.isArray(data)) {
        setHeaders(employeeHeaders);
        setRows(data);
      } else if (Object.keys(data).includes('company') && Object.keys(data).includes('directory')) {
        setHeaders(companyHeaders);
        setRows([data.company]);
        setDirectory(_.get(data, 'directory.individuals', null));
      } else {
        setHeaders(companyHeaders);
        setRows([data]);
      }
    }
  };
  const onError = ({ errorMessage }) => console.error(errorMessage);
  const onClose = () => console.log('User exited Finch Connect');

  const { open: openEmployer } = useFinchConnect({
    clientId: sandboxClientId,
    products: ['company', 'directory', 'individual', 'employment', 'payment', 'pay_statement'],
    mode: 'employer',
    sandbox: true,
    onSuccess,
    onError,
    onClose,
  });

  return (
    <div>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Heading mb={8} mt={8}>
          <img src={landing_page_logo_sandbox} style={{ height: '300px' }} />
        </Heading>
        {!connected ? (
          <>
            <Text ml={4} mr={4} fontSize="xl">
              Integrate your ERC Today account with Finch Connect
            </Text>
            <Button
              size="lg"
              mt="24px"
              onClick={() => openEmployer()}
              style={{ backgroundColor: '#4a4a4a', color: 'white', marginBottom: '32px' }}
            >
              Connect
            </Button>
          </>
        ) : (
          <div style={{ maxWidth: '700px' }}>
            <Text ml={4} mr={4} fontSize="xl" style={{ marginBottom: '32px' }}>
              Your account has been successfully connected. Thank you!
            </Text>
          </div>
        )}
      </Flex>
    </div>
  );
}

function Main() {
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [directory, setDirectory] = useState(null);
  const [connected, setConnected] = useState(false);

  const onSuccess = async ({ code }) => {
    setConnected(true);
    const res = await axios.post(`https://erc-today-api.tryfinch.com/callback`, {
      code,
      state: 'live',
    });
    if (res.status === 200) {
      const { data } = res;
      if (Array.isArray(data)) {
        setHeaders(employeeHeaders);
        setRows(data);
      } else if (Object.keys(data).includes('company') && Object.keys(data).includes('directory')) {
        setHeaders(companyHeaders);
        setRows([data.company]);
        setDirectory(_.get(data, 'directory.individuals', null));
      } else {
        setHeaders(companyHeaders);
        setRows([data]);
      }
    }
  };
  const onError = ({ errorMessage }) => console.error(errorMessage);
  const onClose = () => console.log('User exited Finch Connect');

  const { open: openEmployer } = useFinchConnect({
    clientId,
    products: ['company', 'directory', 'individual', 'employment', 'payment', 'pay_statement'],
    mode: 'employer',
    onSuccess,
    onError,
    onClose,
  });

  return (
    <div>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Heading mb={8} mt={8}>
          <img src={landing_page_logo} style={{ height: '300px' }} />
        </Heading>
        {!connected ? (
          <>
            <Text ml={4} mr={4} fontSize="xl">
              Integrate your ERC Today account with Finch Connect
            </Text>
            <Button
              size="lg"
              mt="24px"
              onClick={() => openEmployer()}
              style={{ backgroundColor: '#4a4a4a', color: 'white', marginBottom: '32px' }}
            >
              Connect
            </Button>
          </>
        ) : (
          <div style={{ maxWidth: '700px' }}>
            <Text ml={4} mr={4} fontSize="xl" style={{ marginBottom: '32px' }}>
              Your account has been successfully connected. Thank you!
            </Text>
          </div>
        )}
      </Flex>
    </div>
  );
}

export default App;
